import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { Link } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"
import { Container, Grid, Typography } from "@material-ui/core"

import AccommodationsCategoryCard from "../cards/AccommodationsCategoryCard"
import CustomButton from "../buttons/CustomButton"
import { LocaleContext } from "../../contexts/LocaleContext"
import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const useStyles = makeStyles(theme => ({
  intro: {
    marginTop: theme.spacing(3),
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: -1,

    "& p": {
      margin: 0,
    },
  },
  slide: {
    paddingRight: 10,
  },
}))

const AccommodationsLandingBlock = ({ accommodationsLanding, reverse }) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const accommodationsCategories = accommodationsLanding.data.accommodation_categories.map(
    ({ category }) => category.document
  )

  const settings = {
    className: "",
    centerMode: false,
    infinite: accommodationsCategories.length > 1 ? true : false,
    arrows: false,
    pauseOnFocus: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    dots: true,
    dotsClass: "slick-dots",
    autoplay: true,
  }

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          direction={reverse ? "row-reverse" : "row"}
          alignItems="center"
          container
          spacing={3}
        >
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              variant="h1"
              className={classes.intro}
              dangerouslySetInnerHTML={{
                __html: accommodationsLanding.data.intro.html,
              }}
            />
            <Typography
              variant="body1"
              component="div"
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: accommodationsLanding.data.description.html,
              }}
            />
            <CustomButton
              color="primary"
              component={Link}
              to={`/${localizedSlugPrefix(accommodationsLanding)}/${
                accommodationsLanding.uid
              }/`}
            >
              {i18n.offers} {accommodationsLanding.data.title.text}
            </CustomButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <Slider {...settings}>
              {accommodationsCategories.map(category => (
                <div className={classes.slide} key={category.id}>
                  <AccommodationsCategoryCard category={category} />
                </div>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Container>
      <br />
    </>
  )
}

export default AccommodationsLandingBlock

AccommodationsLandingBlock.propTypes = {
  accommodationsLanding: PropTypes.object.isRequired,
  reverse: PropTypes.bool.isRequired,
}
