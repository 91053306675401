import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Divider, Grid, Typography } from "@material-ui/core"

import SEO from "../components/SEO"

import Banner from "../components/Banner"
import AccommodationsLandingBlock from "../components/homepage/AccommodationsLandingBlock"

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 30,
  },
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 110px)",
    },
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    top: 0,
    left: 0,

    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
  },
  pageTitle: {
    [theme.breakpoints.down("sm")]: {},
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: -1,
    textAlign: "center",
  },
  mainImage: {
    height: "100%",
  },
}))

const Index = ({
  data: { homepage, tourPage, accommodationPage },
  pageContext: { locale, breadcrumbs, alternateLanguages },
  location,
}) => {
  const classes = useStyles()

  const meta = {
    title: homepage.data.meta_title,
    description: homepage.data.meta_description,
    keywords: homepage.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const schema = homepage.data.schema_json.text || ""
  const ogp = homepage.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: `${homepage.data.hero_image.fluid.src}`,
  })

  ogp.push({
    property: "og:image:alt",
    content: homepage.data.intro.text,
  })
  ogp.push({
    property: "og:image:width",
    content: homepage.data.hero_image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: homepage.data.hero_image.dimensions.height,
  })

  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />

      <Container maxWidth="lg">
        <Grid direction="row" justify="center" container>
          <Grid item xs={12} md={6}>
            <Typography
              component="h1"
              variant="h1"
              className={classes.pageTitle}
              dangerouslySetInnerHTML={{
                __html: homepage.data.intro.html,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Divider />
      {homepage.data.body.map((slice, idx) => (
        <div key={slice.id}>
          {slice.slice_type === "accommodation_section" && (
            <>
              <br />
              <AccommodationsLandingBlock
                accommodationsLanding={
                  slice.primary.accommodation_landing.document
                }
                reverse={idx % 2 === 1}
              />
              <br />
              <Divider />
            </>
          )}
        </div>
      ))}

      <Container maxWidth="lg">
        <br />
        <br />
        <Banner data={homepage.data.banner_1.document.data} />
      </Container>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        intro {
          html
          text
        }
        hero_image {
          alt
          dimensions {
            height
            width
          }
          fluid(maxWidth: 1980, maxHeight: 1070) {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          ... on PrismicHomepageBodyAccommodationSection {
            id
            primary {
              accommodation_landing {
                document {
                  ... on PrismicAccommodationLanding {
                    id
                    uid
                    lang
                    data {
                      image {
                        alt
                        dimensions {
                          height
                          width
                        }

                        fluid(maxWidth: 1980, maxHeight: 1070) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      intro {
                        html
                      }
                      title {
                        text
                      }
                      description {
                        html
                      }
                      accommodation_categories {
                        category {
                          document {
                            ... on PrismicAccommodationCategory {
                              id
                              data {
                                title {
                                  text
                                }
                                subtitle {
                                  text
                                }
                                intro {
                                  text
                                }
                                coordinates {
                                  longitude
                                  latitude
                                }
                                image {
                                  alt
                                  dimensions {
                                    height
                                    width
                                  }

                                  fluid(maxWidth: 1980, maxHeight: 1070) {
                                    ...GatsbyPrismicImageFluid
                                  }
                                }
                                parent {
                                  document {
                                    ... on PrismicAccommodationLanding {
                                      id
                                      uid
                                    }
                                  }
                                }
                              }
                              uid
                              lang
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicHomepageBodyTours {
            id
            items {
              tour {
                document {
                  ... on PrismicTour {
                    id
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
        }

        banner_1 {
          document {
            ... on PrismicBanner {
              data {
                text_1 {
                  html
                }
                text_2 {
                  html
                }
                url
              }
            }
          }
        }

        banner_2 {
          document {
            ... on PrismicBanner {
              data {
                text_1 {
                  html
                }
                text_2 {
                  html
                }
                url
              }
            }
          }
        }
      }
    }

    tourPage: prismicToursPage(lang: { eq: $locale }) {
      uid
    }

    accommodationPage: prismicAccommodationsPage(lang: { eq: $locale }) {
      uid
    }
  }
`

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
    tourPage: PropTypes.object.isRequired,
    accommodationPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
