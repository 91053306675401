import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core'

import { LocaleContext } from "../contexts/LocaleContext"
import BackgroundImage from '../img/banner_bg.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundColor: theme.palette.primary.main,
    backgroundRepeat: 'repeat',
    padding: theme.spacing(2),
  },

  text_1: {
    fontFamily: 'Amatic SC, cursive',
    fontSize: 45,

    color: theme.palette.common.white,
    textTransform: 'uppercase',
    margin: 0,

    '& p,& h1,& h2,& h3,& h4,& h5,& h6,': {
      margin: 0,
    },
  },

  text_2: {
    color: theme.palette.common.white,
    fontFamily: 'Amatic SC, cursive',
    fontSize: 30,

    '& p,& h1,& h2,& h3,& h4,& h5,& h6,': {
      margin: 0,
    },
  },

  button: {
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    background: theme.palette.common.white,
  },
}))

const Banner = ({ data }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const classes = useStyles()

  return (
    <Card className={classes.root} variant='outlined'>
      <CardContent>
        <Grid
          container
          direction='row'
          justify='space-between'
          alignItems='center'
          spacing={2}
        >
          <Grid item>
            <Typography
              className={classes.text_1}
              component='span'
              dangerouslySetInnerHTML={{
                __html: data.text_1.html,
              }}
            />
            <Typography
              className={classes.text_2}
              component='span'
              dangerouslySetInnerHTML={{
                __html: data.text_2.html,
              }}
            />
          </Grid>
          <Grid item>
            <Button
              size='large'
              variant='contained'
              className={classes.button}
              to={data.url}
              component={Link}
            >
              {i18n.seeMore}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Banner

Banner.propTypes = {
  data: PropTypes.object.isRequired,
}
